<template>
    <div class="app-main__inner">
        <div class="container mb-3 dm-sans-font">
            <!-- <div class="flex justify-between aligng-items-center">
                <div class="text-2xl text-gray-800 font-semibold">{{ $t('addWebshop') }} (Shopify)</div>
                <div class="flex justify-between space-x-5 align-items-center">
                    <router-link :to="{ name : 'addwoo'}" class="flex justify-center align-items-center font-semibold focus:bg-gray-800 text-base bg-gray-900 h-9 w-9 rounded shadow-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    </router-link>
                </div>
            </div> -->
            <!-- <div class="flex justify-center content-center md:mt-10" v-if="showAuthorize">
                <form @submit.prevent="authorizeShopify">
                    <div class="p-5 rounded shadow-sm bg-white">
                        <div class="form-group w-full">
                            <label class="field-label">{{ $t('shopName') }}<span class="help-tooltip cursor-pointer" title="Tooltip Sample"></span></label>
                            <input type="text" v-model="shopifyForm.shopName" class="form-control" required>
                        </div>
                        <div class="flex justify-center">
                            <button :disabled='authorizing' type="submit" class="text-white bg-blue-900 px-3 py-1 rounded font-semibold">
                                <span v-if="authorizing" class="flex space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {{ $t('connecting') }}
                                </span>
                                <span v-else>Authorize Shopify</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div> -->
            <div class="bg-white mb-3">
                <div class="row align-items-center p-2">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{ $t('shopName') }}<span class="help-tooltip cursor-pointer" :title="$t('shopName')"></span></label>
                            <div>
                                <input :disabled='shopifyForm.shop || queryName' type="text" v-model="shopifyForm.shopName" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="md:mt-10">
                                <button :disabled="connectedBtn || authorizing" class="px-3 py-2 bg-green-500 text-white rounded-md" @click="authorizeShopify">
                                    <span v-if="authorizing" class="flex justify-center">
                                        <svg class="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </span>
                                    <span v-else>{{ $t('connectShopify') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center p-2">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{ $t('approachingLowStockQty') }}<span class="help-tooltip cursor-pointer" :title="$t('approaching_low_stock_quantityHelpText')"></span></label>
                            <div>
                                <input type="number" min="0" v-model="shopifyForm.approachingLowQty" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{ $t('criticalLowStockQty') }}<span class="help-tooltip cursor-pointer" :title="$t('critical_low_stock_quantityHelpText')"></span></label>
                            <div>
                                <input type="number" min="0" v-model="shopifyForm.criticalLowQty" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{ $t('sufficientQty') }}<span class="help-tooltip cursor-pointer" :title="$t('sufficient_stock_quantityHelpText')"></span></label>
                            <div>
                                <input type="number" min="0"  v-model="shopifyForm.sufficientQty" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col p-2">
                    <div class="form-group flex align-items-center space-x-3">
                        <div>
                            <input type="checkbox" v-model="shopifyForm.syncStockWebshop" class="checkbox-height  h-4 w-4">
                        </div>
                        <label class="field-label">{{ $t('syncStockFromSupplier') }}<span class="help-tooltip cursor-pointer" :title="$t('syncStockFromSupplierHelpText')"></span></label>
                    </div>
                    <div class="form-group flex align-items-center space-x-3">
                        <div>
                            <input type="checkbox" v-model="shopifyForm.automateOrder" class="checkbox-height  h-4 w-4">
                        </div>
                        <label class="field-label">{{ $t('placeAutomaticOrders') }}<span class="help-tooltip cursor-pointer" :title="$t('placeAutomaticOrdersHelpText')"></span></label>
                    </div>
                </div>

                <div class="flex justify-center mb-5">
                    <button @click="saveUserShopifyData" :disabled='connecting' class="flex justify-center align-items-center space-x-4 bg-green-500 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3 mb-5">
                        <span v-if="connecting" class="flex align-items-center space-x-3">
                            <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            {{ $t('updating') }}
                        </span>
                        <span v-else>{{ $t('update') }}</span>
                    </button>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name : 'AddShopify',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
        },
        data () {
            return {
                authorizing : false,
                showAuthorize : true,
                connecting : false,
                connectedBtn : false,
                shopifyForm : {
                    shopName : '',
                    code : null,
                    state : null,
                    hmac : null,
                    host : null,
                    shop : null,
                    timestamp : null,
                    note : '',
                    companyId : '',
                    approachingLowQty : '',
                    criticalLowQty : '',
                    sufficientQty : '',
                    syncStockWebshop : false,
                    automateOrder : false
                },
                checkedOnce : null,
                queryName : null
            }
        },
        computed : {
            ...mapGetters({
                // GET_SHOP_LIST : 'customer/GET_SHOP_LIST',
                GET_CONNECTED_SHOP_LIST : 'customer/GET_CONNECTED_SHOP_LIST',
                GET_WEBSHOP_PROVIDER : 'customer/GET_WEBSHOP_PROVIDER'
            })
        },
        mounted () {
            // window.location.href = 'add-shopify'
            this.getProvider()
            this.getShopList()

            const { code, state, hmac, host, shop, timestamp, name } = this.$route.query
            if (code && state && hmac && host && shop && timestamp) {
                this.showAuthorize = false
                this.connectedBtn = true
                this.shopifyForm.code = code
                this.shopifyForm.state = state
                this.shopifyForm.hmac = hmac
                this.shopifyForm.host = host
                this.shopifyForm.shop = shop
                this.shopifyForm.timestamp = timestamp
                this.shopifyForm.shopName = shop
            } else {
                this.showAuthorize = true
            }

            if (name) {
                this.queryName = name
                // Pull webshop info for shopify
                this.getShopifyInfo()
            }
        },
        methods : {
            getShopifyInfo () {
                const data = window.localStorage.getItem('__shop__d')
                if (data) {
                    const payload = {
                        edit : true,
                        webshopId : data
                    }
                    this.$store.dispatch('customer/getConnectedWebshop', payload)
                    .then(res => {
                        this.shopifyForm.shopName = res.data.name
                        this.shopifyForm.note = res.data.note
                        this.shopifyForm.automateOrder = res.data.place_automatic_orders
                        this.shopifyForm.sufficientQty = res.data.sufficient_stock_quantity
                        this.shopifyForm.approachingLowQty = res.data.approaching_low_stock_quantity
                        this.shopifyForm.criticalLowQty = res.data.critical_low_stock_quantity
                        this.shopifyForm.syncStockWebshop = res.data.sync_stock_quantity_from_supplier_to_integration
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                            window.Bus.$emit('sign-out')
                        }
                    })
                }
            },
            getShopList () {
            	let url = `?is_connected=True`
                this.$store.dispatch('customer/getConnectedShopList', { data: url})
            },
            getProvider () {
                this.$store.dispatch('customer/getIntegrationProvider')
                .then(_ => {
                    const { code, state, hmac, host, shop, timestamp } = this.$route.query
                    if (code && state && hmac && host && shop && timestamp) {
                        const check = window.localStorage.getItem('checkonce')
                        if (!check) {
                            this.sendResponseAutomatically()
                        }
                    }
                })
                .catch(_ => {})
            },
            authorizeShopify () {
                if (this.shopifyForm.shopName === '') {
                    this.$services.helpers.notification(this.$t('shopNameRequire'), 'error', this)
                } else {
                    this.authorizing = true
                    const payload = {
                        shop : this.shopifyForm.shopName,
                        permission_url : true,
                        provider : 3,
                    }
                    this.$store.dispatch('customer/authorizeShopify', payload)
                    .then(res => {
                        this.authorizing = false
                        this.saveInformation()
                        setTimeout(() => {
                            window.open(res.data.success, '_self')
                        }, 1000)
                    })
                    .catch(err => {
                        this.authorizing = false
                        if (err.response.status === 404) {
                           this.$services.helpers.notification(this.$t('noWebshop'), 'error', this)
                        } else if (err.response.status === 401) {
                           this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                            window.Bus.$emit('sign-out')
                        } else {
                          this.$services.helpers.notification('Error', 'error', this)
                       }
                    })
                }
            },
            saveInformation () {
                const saveShopify = window.localStorage.getItem('__shopify__data')
                if (!saveShopify) {
                    const data = { shop_name : this.shopifyForm.shopName }
                    window.localStorage.setItem('__shopify__data', JSON.stringify(data))
                }
            },
            sendResponseAutomatically () {
                const loading = this.$loading({
                    lock: true,
                    text: this.$t('pleaseWait'),
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                window.localStorage.setItem('checkonce', true)
                const filterProvider = this.GET_WEBSHOP_PROVIDER.filter(item => item.name === 'Shopify')
                // const data = window.localStorage.getItem('__shopify__data')
                // const companyId = JSON.parse(data).company_id
                // this.shopifyForm.companyId = companyId
                this.shopifyForm.shopName = this.shopifyForm.shop
                const payload = {
                    host : this.shopifyForm.host,
                    hmac : this.shopifyForm.hmac,
                    state : this.shopifyForm.state,
                    timestamp : this.shopifyForm.timestamp,
                    code : this.shopifyForm.code,
                    shop : this.shopifyForm.shop,
                    // company_id : companyId,
                    provider : filterProvider[0].id
                }
                this.$store.dispatch('customer/connectShopify', payload)
                .then(res => {
                    loading.close()
                    this.connecting = false
                    this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                    const data = window.localStorage.getItem('__shopify__data')
                    const storageData = JSON.parse(data)
                    storageData.integration_id = res.data.integration_id
                    window.localStorage.setItem('__shopify__data', JSON.stringify(storageData))
                    // this.shopifyForm.companyId = storageData.company_id
                    this.shopifyForm.shopName = this.shopifyForm.shop
                })
                .catch(err => {
                    this.autoError = true
                    loading.close()
                    this.connecting = false
                    this.$services.helpers.notification(err.response.data.error, 'error', this)
                    setTimeout(() => {
                        window.location.href = 'add-shopify'
                    }, 2000)
                    if (err.response.status === 401) {
                        this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                        window.Bus.$emit('sign-out')
                    }
                })
            },
            saveUserShopifyData () {
                const data = window.localStorage.getItem('__shopify__data')
                const editData = window.localStorage.getItem('__shop__d')


                if (!data && !editData) {
                    return this.$services.helpers.notification(this.$t('unAuthorizedShopify'), 'error', this)
                }
                let webshop = ''
                webshop = this.queryName ? window.localStorage.getItem('__shop__d') : JSON.parse(data).integration_id

                if (this.shopifyForm.approachingLowQty === '' || this.shopifyForm.criticalLowQty === '' ||
                    this.shopifyForm.syncStockWebshop === '' || this.shopifyForm.automateOrder === '') { // this.shopifyForm.companyId === ''
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (!webshop) {
                    this.$services.helpers.notification(this.$t('invalidUpdateResponse'), 'error', this)
                } else {
                    this.connecting = true
                    const payload = {
                        webshop_id_save : webshop,
                        approaching_low_stock_quantity : this.shopifyForm.approachingLowQty,
                        critical_low_stock_quantity : this.shopifyForm.criticalLowQty,
                        place_automatic_orders : this.shopifyForm.automateOrder,
                        sufficient_stock_quantity : parseInt(this.shopifyForm.sufficientQty),
                        sync_stock_quantity_from_supplier_to_integration : this.shopifyForm.syncStockWebshop,
                    }
                    this.$store.dispatch('customer/updateConnectedWebshop', payload)
                    .then(_ => {
                        this.connecting = false
                        this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                        // this.clearField()
                        setTimeout(() => {
                            window.localStorage.removeItem('__shopify__data')
                            window.localStorage.removeItem('checkonce')
                            if (!this.queryName) {
                                window.location.href = 'add-shopify'
                            }
                            this.showAuthorize = true
                        }, 1000)
                    })
                    .catch(err => {
                        this.connecting = false
                        if (err.response.status === 404) {
                           this.$services.helpers.notification($t('noWebshop'), 'error', this)
                        } else if (err.response.status === 401) {
                           this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                            window.Bus.$emit('sign-out')
                        } else {
                          this.$services.helpers.notification('Error', 'error', this)
                       }
                    })
                }
            },
            clearField () {
                Object.keys(this.shopifyForm).forEach(element => {
                    if (typeof (this.shopifyForm[element]) !== 'string') {
                        this.shopifyForm[element] = false
                    } else {
                        this.shopifyForm[element] = ''
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.c-border{
    border-width: 1px !important;
}
.form-control{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem .75rem !important;
}
.form-select{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem 1.5rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.btn-border-all{
    border: 1px solid $theme-secondary-color !important;
    color: $theme-secondary-color !important;
}
.table th, .table td {
    border: unset !important;
}
</style>
